import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tecnology',
  templateUrl: './tecnology.component.html',
  styleUrls: ['./tecnology.component.scss']
})
export class TecnologyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
