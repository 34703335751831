import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {

  slidesClients: any = [
    { id: 1, title: "", alt: "", src: "assets/clientes/seguradora_suhai.png"},
    { id: 2, title: "", alt: "",src: "assets/clientes/seguradora_hdi.png"},
    { id: 3, title: "", alt: "",src: "assets/clientes/seguradora_pier.png"},
    { id: 4, title: "", alt: "",src: "assets/clientes/seguradora_porto_seguro.png"},
    { id: 5, title: "", alt: "",src: "assets/clientes/seguradora_tokio_marine.png"},
    { id: 6, title: "", alt: "",src: "assets/clientes/seguradora_azul.png"},
    { id: 7, title: "", alt: "",src: "assets/clientes/seguradora_itau.png"},
];

teamSlides: OwlOptions = {
loop: true,
    margin: 20,
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
        0: {
            items: 3,
        },
        575: {
            items: 3,
        },
        576: {
            items: 3,
        },
        768: {
            items: 4,
        },
        992: {
            items: 4,
        },
        1200: {
            items: 6,
        }
    }
}

  constructor() { }

  ngOnInit(): void {
  }

}
