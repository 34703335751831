<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Tecnologias</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Tecnologias</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="feature-area bg-color pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Tecnologias</h2>
                        <p>Inovando com o seu sistema de regulação de sinistro via imagem, a SDS está presente em todos os lugares! Com alcance em nível nacional, vem ajudando as Seguradoras com um processo muito mais fácil, ágil e seguro!</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Capturador de Imagens</li>
                        <li><i class="flaticon-correct"></i> Sistema de Gestão de Regulação</li>
                        <li><i class="flaticon-correct"></i> IA de Análise de Imagens</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>