<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Serviços</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Serviços</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span>Smart Services</span> -->
            <h2>Serviços</h2>
            <p>Todos os serviços são desenvolvidos em plataforma Digital, que garante Segurança, Rapidez e Qualidade.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/smartphone.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Captura de imagens</h3>
                        <p>Possuímos um sistema de captação que permite a obtenção das imagens através das oficinas, reguladores, seguradoras, segurados ou terceiro.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/marcador-mapa.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Local</h3>
                        <p>Rede de profissionais autônomos nas principais regiões do Brasil; Sistema de controle das vistorias com visão dos SLAs por fase e por seguradora.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/despertador.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Velocidade</h3>
                        <p>Para que deixar para amanhã o que se pode iniciar hoje. Foi pensando nisso que a SDS criou a Regulação Hibrida.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/analise-crescimento.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Processos</h3>
                        <p>Atuar em processos autorizados, para um acompanhamento do andamento e evitar futuras reclamações.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/alerta-geral.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Aviso</h3>
                        <p>Atuar antes de devolver o lote, realizando um contato com o cliente para capturar as imagens.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/serviço-carro.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Guincheiro</h3>
                        <p>Aproveitar as imagens coletadas pelos guinchos para a regulação. Ganhar agilidade no processo de regulação.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>