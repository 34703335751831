<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Sobre</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Sobre</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Quem Somos</h2>
                        <p>A SDS é uma empresa brasileira com foco no mercado Segurador. Nosso objetivo é inovar o modelo de regulação de sinistros e ajudar as Seguradoras com um processo muito mais fácil, ágil e seguro!</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/about.png" class="rounded mx-auto d-block" alt="company" />
                </div>
            </div>
        </div>

        <div class="row mt-4 pt-4">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/objetivo.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Missão</h3>
                        <p>Oferecer aos nossos clientes serviços de qualidade, com alta performance e tecnologia de ponta em soluções para regulação de sinistro sempre buscando a sua satisfação total.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/visualizacao-2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Visão</h3>
                        <p>Ser uma empresa sólida e confiável reconhecida pelo mercado segurador como principal parceiro em regulação e soluções em sinistro.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/diamante.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Valores</h3>
                        <p>Ética, responsabilidade social e ambiental, comprometimento, inovação e respeito pelo cliente e colaboradores, são os valores que norteiam a SDS.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>