<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Clientes</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Clientes</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Nossos Clientes</h2>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-container *ngFor="let slide of slidesClients">
                    <ng-template carouselSlide [id]="slide.id">
                        <img [src]="slide.src" [alt]="slide.alt" [title]="slide.title" style="max-height:70px; max-width:300px;">
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</section>
