import { Component, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-coverage',
  templateUrl: './coverage.component.html',
  styleUrls: ['./coverage.component.scss'],
  standalone: true,
  imports: [ MatTooltipModule ],
})
export class CoverageComponent implements OnInit {

  constructor() {}

    ngOnInit(): void {
      
    }  

}
