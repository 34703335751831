<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Números</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Números</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nossos números</h2>
            <p>Estamos preparados para melhor atendê-lo!</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-pricing">
                    <div class="service-img">
                        <img src="assets/images/service/verificacao.png" alt="service" />
                    </div>
                    <h2 class="my-4">+8000<sub>/mês</sub></h2>
                        <h3>Regulações</h3>
                        <p>Eficiência operacional para atender o SLA</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-pricing">
                    <div class="service-img">
                        <img src="assets/images/service/atendimento-ao-cliente.png" alt="service" />
                    </div>
                    <h2 class="my-4">+160</h2>
                        <h3>Peritos</h3>
                        <p>Equipe treinada e com vasta experiência</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-pricing">
                    <div class="service-img">
                        <img src="assets/images/service/brasil.png" alt="service" />
                    </div>
                    <h2 class="my-4">24</h2>
                        <h3>Estados</h3>
                        <p>Estamos em todos os estados brasileiros</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-pricing">
                    <div class="service-img">
                        <img src="assets/images/service/avaliacao-do-cliente.png" alt="service" />
                    </div>
                    <h2 class="my-4">+12</h2>
                        <h3>Clientes</h3>
                        <p>Atendemos grandes seguradoras do mercado</p>
                </div>
            </div>
        </div>
        <div class="row p-4" style="background-color: #EDF5FF; margin-top: 4rem; border-radius: 40px;">
            <div class="col-sm-12 col-md-3 text-white">
                <div class="row title-description">
                    <h3>Por que nos escolher?</h3>
                </div>
                <div class="row description">
                    <p>A SDS é uma empresa brasileira com foco no mercado Segurador.</p>
                    <p>A maior razão de ser da Soluções Digitais em Sinistro é facilitar o processo para quem está envolvido
                        neste mercado.</p>
                </div>
            </div>
            <div class="col-sm-12 col-md-3 text-white">
                <div class="row title-description">
                    <h3>Segurança</h3>
                </div>
                <div class="row description">
                    <p>Através de uma plataforma segura, mantemos todas as informações em total sigilo.</p>
                </div>
            </div>
            <div class="col-sm-12 col-md-3 text-white destaque" style="border-radius: 20px;">
                <div class="row title-description">
                    <h3 style="color: #FFFFFF">Facilidade</h3>
                </div>
                <div class="row description">
                    <p style="color: #FFFFFF">Com o nosso software é possível diminuir significativamente os processos e atingir um resultado muito
                        mais rápido e eficiente.</p>
                </div>
            </div>
            <div class="col-sm-12 col-md-3 text-white">
                <div class="row title-description">
                    <h3>Inovação</h3>
                </div>
                <div class="row description">
                    <p>Sendo uma empresa de soluções digitais buscamos tecnologias de ponta existentes no mercado nacional e
                        internacional.</p>
                </div>
            </div>
        </div>
    </div>
</section>